import { graphql } from "gatsby";
import React from "react";
import CustomButton from "../components/button";
import SeoContext from "../components/context/seoContext";
import SEO from "../components/seo";
import Video404 from "../components/Video_404";

const NotFoundPage = (props) => {
  const { sanitySiteSettings } = props.data;

  sanitySiteSettings.pageTitle = `404: Not Found | ${sanitySiteSettings.title || "Siguna"}`;

  return (
    <>
      <SeoContext.Provider value={sanitySiteSettings}>
        <SEO title="404: Not found" />
        <div className="flex py-20 pt-0 flex-col items-center md:flex-row-reverse md:justify-center container">
          <Video404 className="w-full md:w-1/2 " />
          <div className=" flex flex-col items-center md:block">
            <h1 className="text-center md:text-left lg:text-6xl max-w-xl md:mr-16 text-navy">
              Sorry... something went wrong
            </h1>
            <p className="text-center md:text-left md:text-xl md:mt-8 md:mb-12 text-navy">
              You just hit a route that doesn&#39;t exist.
            </p>
            <CustomButton level="primary" link="/homepage" className="my-8 md:my-16">
              Go to homepage
            </CustomButton>
          </div>
        </div>
      </SeoContext.Provider>
    </>
  );
};

export default NotFoundPage;
export const query = graphql`
  query {
    sanitySiteSettings {
      ...Settings
    }
  }
`;
