import React from "react";
import poster_image from "./poster.png";
import mp4Video from "./../../static/404_anim_close.mp4"
import webmVideo from "./../../static/404_anim_close.webm"

const Video404 = ({ props, className }) => {
  return (
    <div className={className}>
      <video autoPlay muted loop poster={poster_image}>
        <source src={mp4Video} type={"video/mp4"} />
        <source src={webmVideo} type={"video/webm"} />
      </video>
    </div>
  );
};

export default Video404;
